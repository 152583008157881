import React, { FC, useState, useEffect, PropsWithChildren, ReactNode, useRef } from "react";
import { ReactComponent as Profile } from "./assets/images/profile.svg";
import { useAuth } from "./utils/hooks/UseAuth";
import { AuthProvider } from "./utils/hooks/UseAuth";
import useClickOutside from "./utils/hooks/UseClickOutside";

interface AuthDropdownProps {}

const AuthDropdownWrapper: FC = () => {
  return (
    <AuthProvider>
      <AuthDropdown></AuthDropdown>
    </AuthProvider>
  );
};

const AuthDropdown: FC<PropsWithChildren<AuthDropdownProps>> = (props: PropsWithChildren<AuthDropdownProps>) => {
  const [open, setOpen] = useState(false);
  const { login, logout, signup, isAuthenticated, loadToken, getAccount } = useAuth();
  const token = loadToken();
  const account = getAccount();
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const signUpButtons = document.querySelectorAll(".signup-button");
    signUpButtons.forEach(button => {
      button.addEventListener('click', event => {
        event.preventDefault();
        signup();
      });
    });
  });

  useClickOutside(popupRef, () => {
    setOpen(false);
  });

  return (
    <div className="AuthDropdown">
      <button
        className="AuthDropdown__Toggle"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Profile />
      </button>
      <div className="AuthDropdown__Inner" hidden={!open} ref={popupRef}>
        {isAuthenticated && token && account ? (
          <>
            <div>
              Signed in as
              <br />
              <span>{account.username}</span>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                logout();
                setOpen(false);
              }}
            >
              Logout
            </button>
          </>
        ) : (
          <>
            <div>
              Sign in to Jurisage
              <br />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                login();
                setOpen(false);
              }}
            >
              Login
            </button>
            <div>or</div>
            <button
              onClick={(e) => {
                e.preventDefault();
                signup();
                setOpen(false);
              }}
            >
              Sign Up
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthDropdownWrapper;
