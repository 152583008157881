import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AuthDropdown from "./AuthDropdown";
import reportWebVitals from "./reportWebVitals";

const menuElement = document.querySelector("#et-top-navigation") as HTMLElement;

if (menuElement) {
  // Button element exists only on the dashboard page
  const buttonElement = document.querySelector("#et-top-navigation .menu_cta") as HTMLLinkElement;
  const dropdownElement = document.createElement("li");
  dropdownElement.id = "myjr-auth-dropdown";
  dropdownElement.className = "menu-item";
  menuElement.appendChild(dropdownElement);
  if (buttonElement) {
    buttonElement.remove();
    const dropdown = ReactDOM.createRoot(document.getElementById("myjr-auth-dropdown") as HTMLElement);
    dropdown.render(<AuthDropdown />);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
