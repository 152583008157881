import React, { useState, PropsWithChildren } from "react";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
import jwtDecode from "jwt-decode";

type jwtADTokenContent = {
  aud: string[];
  azp: string;
  exp: number;
  nbf: number;
  iat: number;
  iss: string;
  sub: string;
  extension_AppAccess: boolean;
  extension_StripeCustomerId: string;
  name: string;
  given_name: string;
  family_name: string;
  emails: string[];
};

export const b2cPolicies = {
  names: {
    SignIn: "B2C_1_SignIn",
    SignUp: "B2C_1_Signup",
  },
  authorities: {
    B2C_1_SignIn: {
      authority: "https://account.jurisage.com/jurisageauth.onmicrosoft.com/B2C_1_SignIn",
    },
    B2C_1_Signup: {
      authority: "https://account.jurisage.com/jurisageauth.onmicrosoft.com/B2C_1_Signup",
    },
  },
  authorityDomain: "https://account.jurisage.com/jurisageauth.onmicrosoft.com",
};

export const msalConfig: Configuration = {
  auth: {
    clientId: "3152b275-d4c0-4cd6-b644-466c40bf77b7",
    authority: b2cPolicies.authorities.B2C_1_SignIn.authority,
    knownAuthorities: ["https://account.jurisage.com/jurisageauth.onmicrosoft.com"],
    // redirectUri: "http://localhost:3000/blank.html", // uncomment for local dev
    redirectUri:
      process.env.REACT_APP_BUILD_ENVIRONMENT !== "development"
        ? "https://jurisage.com/blank.html"
        : "https://jurisagestage.wpengine.com/blank.html",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii || process.env.REACT_APP_BUILD_ENVIRONMENT !== "development") {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const application = new PublicClientApplication(msalConfig);

export function useAuth() {
  const [errorMessage] = useState("" as string | undefined);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const storeToken = (accessToken: string) => {
    localStorage.setItem("storedToken", accessToken);
    return accessToken;
  };

  const removeToken = () => {
    localStorage.removeItem("storedToken");
  };

  const getToken = () => {
    return localStorage.getItem("storedToken") || "";
  };

  function navigateAfterLogin() {
    if (process.env.REACT_APP_BUILD_ENVIRONMENT !== "development") {
      window.location.href = "/login-welcome";
    } else {
      window.location.reload();
    }
  }

  function navigateAfterLogout() {
    window.location.reload();
    return;
  }

  const login = async () => {
    const params = {
      scopes: ["openid", "offline_access", "3152b275-d4c0-4cd6-b644-466c40bf77b7"],
      response_type: "token",
      prompt: "select_account",
    };

    try {
      const tokenResponse = await instance.acquireTokenSilent({ ...params, account: accounts[0] });
      const accessToken = tokenResponse.accessToken;
      storeToken(accessToken);
      navigateAfterLogin();
      return accessToken;
    } catch (error) {
      const loginResponse = await instance.loginPopup();
      const tokenResponse = await instance.acquireTokenSilent({ ...params, account: loginResponse.account! });
      const accessToken = tokenResponse.accessToken;
      storeToken(accessToken);
      navigateAfterLogin();
      return accessToken;
    }
  };

  const logout = async () => {
    await instance.logoutPopup().then(() => {
      removeToken();
      localStorage.clear();
      navigateAfterLogout();
    });
  };

  const signup = async () => {
    const params = {
      scopes: ["openid", "offline_access", "3152b275-d4c0-4cd6-b644-466c40bf77b7"],
      response_type: "token",
      prompt: "select_account",
    };
    try {
      const tokenResponse = await instance.acquireTokenSilent({ ...params, account: accounts[0] });
      const accessToken = tokenResponse.accessToken;
      storeToken(accessToken);
      navigateAfterLogin();
      return accessToken;
    } catch (error) {
      const response = await instance.acquireTokenPopup({
        authority: b2cPolicies.authorities.B2C_1_Signup.authority,
        scopes: ["openid", "offline_access", "3152b275-d4c0-4cd6-b644-466c40bf77b7"],
        prompt: "select_account",
      });
      const tokenResponse = await instance.acquireTokenSilent({ ...params, account: response.account! });
      const accessToken = tokenResponse.accessToken;
      storeToken(accessToken);
      window.location.href = "/dashboard/payment";
      return accessToken;
    }
  };

  return {
    isAuthenticated,
    storeToken,
    errorMessage,
    isPaid() {
      const token = getToken();
      if (!token) return true; // @TODO: Fix error that occurs when JWT cleared
      return UserIsPaid(token);
    },
    async getTokenAfterSignUp() {
      const params = {
        scopes: ["openid", "offline_access", "3152b275-d4c0-4cd6-b644-466c40bf77b7"],
        response_type: "token",
        prompt: "select_account",
      };
      const tokenResponse = await instance.loginPopup({ ...params, account: accounts[0] });
      const accessToken = tokenResponse.accessToken;
      storeToken(accessToken);
    },
    login,
    logout,
    signup,
    loadToken() {
      return getToken();
    },
    getInstance() {
      return instance;
    },
    getInProgress() {
      return inProgress;
    },
    getAccount() {
      return accounts[0];
    },
  };
}

export function AuthProvider(props: PropsWithChildren) {
  return <MsalProvider instance={application}>{props.children}</MsalProvider>;
}

function UserIsPaid(jwt: string) {
  const decoded: jwtADTokenContent = jwtDecode(jwt);
  if (decoded.extension_AppAccess) {
    return true;
  }
  return false;
}
